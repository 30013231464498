export function directUploadGeneralInitialize(event) {
  const { target, detail } = event;
  const { id, file } = detail;
  target.insertAdjacentHTML(
    "beforebegin",
    `
  <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
  <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
  <span class="direct-upload__filename">${file.name}</span>
  </div>
  `
  );
}

export function directUploadGeneralStart(event) {
  const { id, file } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.remove("direct-upload--pending");
}

export function directUploadGeneralProgress(event) {
  const { id, progress } = event.detail;
  const progressElement = document.getElementById(`direct-upload-progress-${id}`);
  progressElement.style.width = `${progress}%`;
}

export function directUploadGeneralError(event) {
  event.preventDefault();
  const { id, error } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--error");
  element.setAttribute("title", error);
}

export function directUploadGeneralEnd(event) {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  if (!element.className.includes("error")) element.classList.add("direct-upload--complete");
}
