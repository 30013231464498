import {
  directUploadGeneralEnd,
  directUploadGeneralError,
  directUploadGeneralInitialize,
  directUploadGeneralProgress,
  directUploadGeneralStart,
} from "../direct-upload-events";

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("trix");
require("@rails/actiontext");
require("@rails/activestorage").start();

window.addEventListener("direct-upload:initialize", directUploadGeneralInitialize);

window.addEventListener("direct-upload:start", directUploadGeneralStart);

window.addEventListener("direct-upload:progress", directUploadGeneralProgress);

window.addEventListener("direct-upload:error", directUploadGeneralError);

window.addEventListener("direct-upload:end", directUploadGeneralEnd);

function hideElements(elements) {
  elements.forEach((element) => {
    if (element) element.classList.add("hidden");
  });
}

function unhideElements(elements) {
  elements.forEach((element) => {
    if (element) element.classList.remove("hidden");
  });
}

export async function fetchNewPublication(publicationPath, publicationTag) {
  if (publicationTag === document.getElementById("selected-publication").innerText) {
    return;
  }
  const publicationElements = [
    document.getElementById("publication-title"),
    document.getElementById("publication-subtitle"),
    document.getElementById("publication-content"),
    document.getElementById("publication-show-image"),
    document.getElementById("publication-website"),
  ];
  const spinnerElement = [document.getElementById("publication-spinner")];

  hideElements(publicationElements);
  unhideElements(spinnerElement);

  const url = `${publicationPath}`;
  const request = {
    method: "GET",
  };

  try {
    const response = await fetch(url, request);
    const result = await response.json();
    if (result["success"] === "true") {
      const newTitle = await result["title"];
      const newSubtitle = await result["subtitle"];
      const newContent = await result["content"];
      const newImage = await result["image"];
      const newWebsite = await result["website"];
      const newTag = await result["tag"];

      publicationElements[0].innerText = newTitle;
      publicationElements[1].innerText = newSubtitle;
      publicationElements[2].innerHTML = newContent;
      publicationElements[3].setAttribute("src", newImage);
      publicationElements[4].setAttribute("href", newWebsite);

      const selectedPublicationElement = document.getElementById("selected-publication");
      selectedPublicationElement.innerText = newTag;
    }
  } catch (error) {
    console.error(error);
  }
  unhideElements(publicationElements);
  hideElements(spinnerElement);
}

export * from "../direct-upload-events";
